import React from "react";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { useRedirectToParkerRegister } from "app/routes/Register/hooks";

const Register = () => {
  useRedirectToParkerRegister();

  return <LoaderFullCentered />;
};

export default Register;
